export const en = {
  button: {
    signUp: 'Agree & Join',
    back: 'Back',
    refresh: 'Refresh',
  },
  message: {
    hello: 'Hello',
  },
}
