import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

import { RouteName } from '@/enums/route-name'

const routes: Array<RouteRecordRaw> = [
  {
    path: '',
    name: RouteName.START,
    component: () => import('@/views/account/start/Start.vue'),
  },
  {
    path: '/sign-in',
    name: RouteName.REGISTER,
    component: () => import('@/views/account/sign-up/SignUp.vue'),
  },
  {
    path: '/sign-up',
    name: RouteName.LOGIN,
    component: () => import('@/views/account/sign-in/SignIn.vue'),
  },
  {
    path: '/examples',
    component: () => import('@/views/examples/Examples.vue'),
  },
  {
    path: '/home',
    name: RouteName.HOME,
    component: () => import('@/views/entry/home/Home.vue'),
  },
]

export const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})
