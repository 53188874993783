import dayjs from 'dayjs'
import {Ref, computed, ref, watchEffect} from 'vue'

export interface ReminderInfo {
    startAt: number
    repeatType: string
}

export function useReminderState(props: Readonly<{ reminder?: ReminderInfo }>) {
  // 初始化 reminder 数据
  const reminder = ref<ReminderInfo>({
    startAt: Date.now(),
    repeatType: 'Weekday',
  })

  // props 数据更新，局部 reminder 数据同步更新
  watchEffect(() => {
    if (props.reminder) {
      reminder.value = props.reminder
    }
  })

  const reminderDisplay = computed(() => {
    const time = dayjs(reminder.value.startAt).format('YYYY/MM/DD HH:mm')
    return `${time} ${reminder.value.repeatType}`
  })

  const reminderTimeLabel = computed(() => {
    return dayjs(reminder.value.startAt).format('YYYY/MM/DD HH:mm')
  })

  return {
    reminder,
    reminderDisplay,
    reminderTimeLabel,
  }
}