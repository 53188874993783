import { Ref, readonly, ref } from 'vue'

interface UseModalState {
  readonly isOpen: Ref<boolean>
  openModal: () => void
  closeModal: () => void
}

export function useModalState(): UseModalState {
  const isOpen = ref(false)

  const openModal = () => {
    isOpen.value = true
  }

  const closeModal = () => {
    isOpen.value = false
  }

  return {
    isOpen: readonly(isOpen),
    openModal,
    closeModal,
  }
}