import dayjs from 'dayjs'
import {Ref} from 'vue'

import {ReminderInfo} from './use-reminder-state'

function getDays() {
  const days = []
  let day = dayjs(Date.now())
  days.push(day.format('YYYY/MM/DD'))
  for (let i = 0; i < 370; i ++) {
    day = day.add(1, 'day')
    days.push(day.format('YYYY/MM/DD'))
  }
  return days
}

function getHours() {
  return ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12']
}

function getMinutes() {
  return ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59']
}

function getPeriods() {
  return ['AM', 'PM']
}

function getRepeatTypes() {
  return ['Week', 'Weekday']
}

function getSelectedIndexOfDay(days: string[], startAt: number) {
  const dayStr = dayjs(startAt).format('YYYY/MM/DD')
  return days.indexOf(dayStr)
}

function getSelectedIndexOfHour(hours: string[], startAt: number) {
  const hourStr = dayjs(startAt).format('hh')
  return hours.indexOf(hourStr)
}

function getSelectedIndexOfMinute(minutes: string[], startAt: number) {
  const minuteStr = dayjs(startAt).format('mm')
  return minutes.indexOf(minuteStr)
}

function getSelectedIndexOfPeriod(periods: string[], startAt: number) {
  const periodStr = dayjs(startAt).format('A')
  return periods.indexOf(periodStr)
}

function getSelectedIndexOfRepeatType(repeatTypes: string[], repeatType: string) {
  return repeatTypes.indexOf(repeatType)
}


const days = getDays()
const hours = getHours()
const minutes = getMinutes()
const periods = getPeriods()
const repeatTypes = getRepeatTypes()

export function usePickerStore(reminder: Ref<ReminderInfo>) {
  const getTimePickerColumns = () => {
    const dayColumn = {
      name: 'day',
      options: days.map(day => ({
        text: day,
        value: day,
      })),
      cssClass: 'day-column',
      selectedIndex: getSelectedIndexOfDay(days, reminder.value.startAt),
    }
    const hourColumn = {
      name: 'hour',
      options: hours.map(hour => ({
        text: hour,
        value: hour,
      })),
      selectedIndex: getSelectedIndexOfHour(hours, reminder.value.startAt),
    }
    const minuteColumn = {
      name: 'minute',
      options: minutes.map(minute => ({
        text: minute,
        value: minute,
      })),
      selectedIndex: getSelectedIndexOfMinute(minutes, reminder.value.startAt),
    }
    const periodColumn = {
      name: 'period',
      options: periods.map(period => ({
        text: period,
        value: period,
      })),
      selectedIndex: getSelectedIndexOfPeriod(periods, reminder.value.startAt),
    }

    return [dayColumn, hourColumn, minuteColumn, periodColumn]
  }
  const getRepeatTypeColumns = () => {
    const repeatTypeColumn = {
      name: 'repeatType',
      selectedIndex: getSelectedIndexOfRepeatType(repeatTypes, reminder.value.repeatType),
      options: repeatTypes.map(day => ({
        text: day,
        value: day,
      })),
    }
    return [repeatTypeColumn]
  }
  return {
    getTimePickerColumns,
    getRepeatTypeColumns,
  }
}