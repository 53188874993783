import {Ref, onMounted, ref} from 'vue'

import {getPrayerList, getPrayerListByIdsApi} from '@/apis/prayer'
import {Prayer} from '@/stores/database/database-schema-v1'

interface UsePrayerListSate {
  readonly prayerList: Ref<Prayer[]>
}

export function usePrayerListState(groupId?: string, prayerIds?: string[]): UsePrayerListSate {
  const prayerList = ref<Prayer[]>([])

  if (!groupId && (!prayerIds || prayerIds.length === 0)) {
    throw new Error('groupId 和 prayerIds 必须存在一个')
  }

  onMounted(async () => {
    let requestPromise = null
    if (groupId) {
      requestPromise = getPrayerList({
        prayerGroupId: groupId,
      })
    }
    else if (prayerIds) {
      requestPromise = getPrayerListByIdsApi({
        prayerIds,
      })
    } else {
      throw new Error('groupId 和 prayerIds 必须存在一个')
    }

    const res = await requestPromise
    prayerList.value = res?.list || []
  })

  return {
    prayerList,
  }
}