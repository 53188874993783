import {Ref, ref} from 'vue'

const musicUrls = [
  'https://voice.dev.xiaojing0.com/01__Inspirational_Pop_Rock___AShamaluevMusic_1556182517487.mp3',
  'https://voice.dev.xiaojing0.com/08_Winnie_s_Flying_Carpet_1564575119474.mp3',
  'https://voice.dev.xiaojing0.com/05tt3ApPWcnEsiPIeZJxgSE4_2H4PAhl72Vw7KK8zfEu7qs1imGczI95lLvj1TCE.mp3',
]

interface UseModalState {
  musicUrl: Ref<string>
  switchMusic: () => void
}

export function useMusicState(): UseModalState {
  const musicUrl = ref('')

  const switchMusic = () => {
    const randomIndex = Math.floor(Math.random() * musicUrls.length)
    musicUrl.value = musicUrls[randomIndex]
  }

  return {
    musicUrl,
    switchMusic,
  }
}