import {AccountSignInParams, AccountSignUpParams} from '@/apis/model/account-model'
import {http} from '@/utils/http'

/**
 * 邮箱登录
 * @param email
 * @param password
 * @returns {Promise}
 */
export const emailSignIn = (data: AccountSignInParams) => {
  return http.post({
    url: '/account/login/email',
    data,
  })
}

/**
 * 邮箱注册
 * @param email
 * @param password
 * @returns {Promise}
 */
export const emailSignUp = (data: AccountSignUpParams) => {
  return http.post({
    url: '/account/signup/email',
    data,
  })
}

/**
 * 账号退出
 * @returns {Promise}
 */
export const accountLogOut= () => {
  return http.get({
    url: '/account/logout',
  })
}

/**
 * 账号注销
 * @returns {Promise}
 */
export const accountDelete= () => {
  return http.get({
    url: '/account/delete',
  })
}

/**
 * 账号验证
 * @param token 用户邮件内的token
 * @returns {Promise}
 */
export const accountCaptchaCheck = (params: { token: string }) => {
  return http.get({
    url: '/account/captcha/check',
    params,
  })
}

/**
 * 发送验证邮件
 * @param uin
 * @returns {Promise}
 */
export const accountCaptchaGet = (params: { uin: string }) => {
  return http.get({
    url: '/account/captcha/get',
    params,
  })
}

/**
 * 账号状态
 * @param uin
 * @returns {Promise}
 */
export const accountState = (params: { uin: string }) => {
  return http.get({
    url: '/account/state',
    params,
  })
}

/**
 * 发送重置密码邮件
 * @param email
 * @returns {Promise}
 */
export const passwordForgetSendEmail = (params: { email: string }) => {
  return http.get({
    url: '/account/password/forget',
    params,
  })
}

/**
 * 重置密码
 * @param password
 * @param token
 * @returns {Promise}
 */
export const passwordReset = (data: { password: string; token: string }) => {
  return http.post({
    url: '/account/password/forget',
    data,
  })
}

