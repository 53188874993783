import { Ref, computed, ref, watch } from 'vue'

import { Account } from '@/stores/database/database-schema-v1'
import { useDBAdapter } from '@/stores/db-adapter'
import { DBAdapter } from '@/stores/db-adapter/index.type'

interface AccountStore extends DBAdapter<Account> {
  accountInfo: Ref<Account | null>
  getIsLoggedIn: () => Promise<boolean>
}

export function useAccountStore(): AccountStore {
  // 使用 useDBAdapter 函数操作数据库
  // dataList 是响应式的, 修改数据库之后会重新执行query, 从而更新dataList
  const dbAdapter = useDBAdapter<Account>('account')

  // 查取account数据
  dbAdapter.query('*')

  // 数据库的数据更新(insert, update, delete)
  dbAdapter.watch((event) => {
    // 更新account数据
    dbAdapter.query('*')
  })

  const accountInfo = computed(() => {
    if (dbAdapter.dataList.value.length === 0) return null
    return dbAdapter.dataList.value[0]
  })

  async function getIsLoggedIn() {
    const list = await dbAdapter.query('*')
    return list.length > 0
  }

  return {
    ...dbAdapter,
    accountInfo,
    getIsLoggedIn,
  }
}