// eslint-disable-next-line import/namespace
import { createI18n } from 'vue-i18n'

import { en } from './en'
import { zh } from './zh'

export const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
    中文: zh,
  },
})
