import { App, getCurrentInstance, ref, render, unref } from 'vue'

import HxxToast from './HxxToast.vue'

import { mountComponent } from '@/utils/mount-component'
import { isObject } from '@/utils/util'

type ToastType = 'loading' | 'fail' | 'warning'

interface ToastOptions {
  message?: string
  type?: ToastType
  icon?: string
  duration?: number
}


function createInstance() {
  const { instance, unmount } = mountComponent({
    setup() {
      const state = ref({
        message: 'loading',
        type: 'loading',
        icon: 'loading-icon',
        duration: 3000,
        isShow: false,
      })

      const toggle = (show: boolean) => {
        state.value.isShow = show
      }

      const open = (options: ToastOptions) => {
        state.value = { ...state.value, ...options }
        toggle(true)
      }


      const close = () => {
        toggle(true)
        unmount()
      }

      const render = () => {
        const attrs: Record<string, unknown> = {
          'onUpdate:isShow': toggle,
        }
        return <HxxToast {...unref(state)} {...attrs} />
      }

      // rewrite render function
      (getCurrentInstance() as any).render = render

      return {
        open,
        close,
      }
    },
  })

  return instance
}


const parseOptions = (message: string | ToastOptions): ToastOptions => {
  if (isObject(message)) {
    return message
  }
  return { message }
}
const hxxToast = (options: ToastOptions) => {
  const toast = createInstance()
  toast.open(parseOptions(options))
  return toast
}

const createMethod = (type: ToastType) => (options: string | ToastOptions) =>
  hxxToast({ type, ...parseOptions(options) })

hxxToast.loading = createMethod('loading')
hxxToast.warning = createMethod('warning')
hxxToast.fail = createMethod('fail')

// eslint-disable-next-line import/no-default-export
export default {
  //类似一个钩子函数
  install: (app: App) => {
    app.config.globalProperties.$hxxToast = hxxToast
  },
}


