import {http} from '@/utils/http'

interface GetPresignedInfoReq {
  filename: string
}
interface GetPresignedInfoRes {
  key: string
  presignedUrl: string
  url: string
}
export const getPresignedInfo = (data: GetPresignedInfoReq): Promise<GetPresignedInfoRes> => {
  return http.get({
    url: '/image/presignedUrl',
    params: data,
  })
}

export async function uploadFile(file: File | Blob, presignedRes: GetPresignedInfoRes) {
  return fetch(presignedRes.presignedUrl, { method: 'PUT', body: file })
}