import { DBLimit, DBOrder } from './index.type'

function getConditionStatement<T>(condition?: Partial<T> | string) {
  const statement = !condition
    ? ''
    : typeof condition === 'string'
      ? condition
      : Object.entries(condition).reduce((pre, [key, value]) => {
        if (typeof value === 'string') {
          return (pre = `${pre} AND ${key}='${value}'`)
        } else {
          return (pre = `${pre} AND ${key}=${value}`)
        }
      }, '')
  return statement.startsWith(' AND')
    ? statement.substring(4, statement.length)
    : statement
}

function getOrderStatement<T>(order?: DBOrder<T>) {
  const statement = !order
    ? ''
    : typeof order.keys === 'string'
      ? `${order.keys} ${order.order}`
      : `${(order.keys as Array<string>).join(',')} ${order.order}`
  return statement
}

function getLimitStatement(limit?: DBLimit) {
  const statement = !limit ? '' : `LIMIT ${limit.limit} OFFSET ${limit.offset}`
  return statement
}

function getSelectStatement(select: Array<string> | '*') {
  const statement = typeof select === 'string' ? select : select.join(',')
  return statement
}

/**
 * 生成 sql 语句的helper
 */
export class SQLStatement<T> {
  statement: string
  tableName: string

  constructor(tableName: string) {
    this.statement = ''
    this.tableName = tableName
  }

  query(select: Array<string> | '*') {
    const selectStr = getSelectStatement(select)
    this.statement = `SELECT ${selectStr} FROM ${this.tableName}`
    return this
  }

  insert(obj: T) {
    const keys = Object.keys(obj).join(',')
    let values = Object.values(obj).reduce((acc, value) => {
      switch (typeof value) {
      case 'string':
        return `${acc},'${value}'`
      case 'number':
        return `${acc},${value}`
      case 'boolean':
        return `${acc},${value ? 1 : 0}`
      default:
        throw new Error(`不支持的数据类型 ${typeof value}`)
      }
    }, '')
    if (values.length > 0) {
      values = values.substring(1, values.length)
    }
    this.statement = `INSERT INTO ${this.tableName} (${keys}) VALUES (${values})`
    return this
  }

  update(changes: Partial<T>) {
    let values = ''
    for (const [key, value] of Object.entries(changes)) {
      switch (typeof value) {
      case 'string':
        values += `,${key}='${value}'`
        break
      case 'number':
        values += `,${key}=${value}`
        break
      case 'boolean':
        values += `,${key}=${value ? 1 : 0}`
        break
      default:
        throw new Error(`不支持的数据类型 ${typeof value}`)
      }
    }
    if (values.length > 0) {
      values = values.substring(1, values.length)
    }
    this.statement = `UPDATE ${this.tableName} SET ${values}`
    return this
  }

  delete() {
    this.statement = `DELETE FROM ${this.tableName}`
    return this
  }

  condition(condition?: Partial<T> | string) {
    const conditionStr = getConditionStatement(condition)
    if (conditionStr) {
      this.statement += ` WHERE ${conditionStr}`
    }
    return this
  }

  order(order?: DBOrder<T>) {
    const orderStr = getOrderStatement(order)
    if (orderStr) {
      this.statement += ` ORDER BY ${orderStr}`
    }
    return this
  }

  limit(limit?: DBLimit) {
    const limitStr = getLimitStatement(limit)
    if (limitStr) {
      this.statement += ` ${limitStr}`
    }
    return this
  }

  value() {
    console.log('nate-log statement:', this.statement)
    return this.statement
  }
}
