import {Prayer} from '@/stores/database/database-schema-v1'
import {http} from '@/utils/http'

/**
 * 创建祷告
 */
interface CreatePrayerReq {
  title: string
  description: string
  remindType?: string
  startTime?: number
}
interface CreatePrayerRes {
  prayerId: string
}
// export const createPrayer = (data: CreatePrayerReq): Promise<CreatePrayerRes> => http.post({
//   url: '/prayer/create',
//   data,
// })
export const createPrayer = async (data: CreatePrayerReq): Promise<string> => {
  return 'success'
}

/**
 * 删除祷告
 */
interface DeletePrayerReq {
  prayerId: string
}
interface DeletePrayerRes {
  prayerId: string
}
export const deletePrayer = (data: DeletePrayerReq): Promise<DeletePrayerRes> => http.post({
  url: '/prayer/delete',
  data,
})

/**
 * 编辑祷告信息
 */
interface UpdatePrayerReq {
  prayerId: string
  title: string
  description: string
}
interface UpdatePrayerRes {
  prayerId: string
}
export const updatePrayer = (data: UpdatePrayerReq): Promise<UpdatePrayerRes> => http.post({
  url: '/prayer/edit',
  data,
})

/**
 * 获取祷告列表
 */
interface GetPrayerListReq {
  prayerGroupId: string
  limit?: number
  offset?: number
}
interface GetPrayerListRes {
  list: Prayer[]
}
export const getPrayerList = (data: GetPrayerListReq): Promise<GetPrayerListRes> => http.get({
  url: '/prayer/list',
  params: data,
})

interface UpdatePrayerReminderReq {
  prayerId: string
  remindType: number
  startTime: number
}
interface UpdatePrayerReminderRes {
  prayerId: string
}
export const UpdatePrayerReminder = (data: UpdatePrayerReminderReq): Promise<UpdatePrayerReminderRes> => http.post({
  url: '/prayer/reminder',
  data,
})

interface PrayerReportReq {
  reportUin: string
  uin: string
  type: string
  comment?: string
  picturesUrl?: string
}
/**
 * 祷告举报
 */
export const prayerReport = (data: PrayerReportReq) => http.post({
  url: '/report/prayer',
  data,
})

interface GetPrayerListByIdsReq {
  prayerIds: string[]
}
interface GetPrayerListByIdsRes {
  list: Prayer[]
}
export const getPrayerListByIdsApi = (data: GetPrayerListByIdsReq): Promise<GetPrayerListByIdsRes> => {
  // return http.get({
  //   url: '/prayer/list/ids',
  //   params: data,
  // })
  return Promise.resolve({
    list: [{
      description: 'Dear Lord,\n' +
        'praythat husbandsandwives would seekto keep purity in their marriage.lprayagainst the temptation of sin.lprayagainst\n' +
        'pornographyand the lies that convince\n' +
        'people to bring things into their marriage that they don\'t need.lpray against things that corrupt marriage.lprayagainst evil. Please Lord,intervene in our hearts,convicting us of sin.Help us all to strive to have purityas a foundation',
      reminderType: 2,
      reminderNextTime: 1867323423,
      title: 'Purity In Marriage',
      createdStamp: 1623423423,
    }, {
      description: 'Dear Lord,\n' +
        'praythat husbandsandwives would seekto keep purity in their marriage.lprayagainst the temptation of sin.lprayagainst\n' +
        'pornographyand the lies that convince\n' +
        'people to bring things into their marriage that they don\'t need.lpray against things that corrupt marriage.lprayagainst evil. Please Lord,intervene in our hearts,convicting us of sin.Help us all to strive to have purityas a foundation',
      reminderType: 2,
      reminderNextTime: 1867323423,
      title: 'Purity In Marriage',
      createdStamp: 1623423423,
    }, {
      description: 'Dear Lord,\n' +
        'praythat husbandsandwives would seekto keep purity in their marriage.lprayagainst the temptation of sin.lprayagainst\n' +
        'pornographyand the lies that convince\n' +
        'people to bring things into their marriage that they don\'t need.lpray against things that corrupt marriage.lprayagainst evil. Please Lord,intervene in our hearts,convicting us of sin.Help us all to strive to have purityas a foundation',
      reminderType: 2,
      reminderNextTime: 1867323423,
      title: 'Purity In Marriage',
      createdStamp: 1623423423,
    }, {
      description: 'Dear Lord,\n' +
        'praythat husbandsandwives would seekto keep purity in their marriage.lprayagainst the temptation of sin.lprayagainst\n' +
        'pornographyand the lies that convince\n' +
        'people to bring things into their marriage that they don\'t need.lpray against things that corrupt marriage.lprayagainst evil. Please Lord,intervene in our hearts,convicting us of sin.Help us all to strive to have purityas a foundation',
      reminderType: 2,
      reminderNextTime: 1867323423,
      title: 'Purity In Marriage',
      createdStamp: 1623423423,
    }],
  } as unknown as GetPrayerListByIdsRes)
}

interface AddPrayCountReq {
  prayerId: string
}
export const addPrayCountApi = (data: AddPrayCountReq): Promise<void> => {
  return http.post({
    url: '/prayer/add-count',
    data,
  })
}

/**
 * 获取自己的祷告列表
 */
interface GetPrayerOwnListRes {
  groupId: string
  groupName: string
  groupType: number
  prayerCreateTime: string
  prayerDescription: string
  prayerId: string
  prayerTitle: string
}
export const getPrayerOwnListApi = (): Promise<GetPrayerOwnListRes>=> http.get({
  url: '/prayer/own/list',
})
