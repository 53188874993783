/* Set sqlite */
import {IonicVue} from '@ionic/vue'
import VConsole from 'vconsole'
import {createApp} from 'vue'

import App from './App.vue'
import {i18n} from './i18n'
import {router} from './routers'

// 后期的数据迁移大致应该会有 2 种方案：
// 1. 进入 app 后后台静默迁移
// 2. 在 某个页面 (如: Splash Screen) 中升级
// import {run} from '@/stores/autoMigrator'
// run()
import hxxToast from '@/components/hxx-toast/hxx-toast'
import {databaseInit} from '@/stores/database'
import {isMobileSafari} from '@/utils/platform'

/* Core CSS required for Ionic components to work properly aabc */
import '@ionic/vue/css/core.css'
import '../src/assets/fonts/fonts.css'
import '../src/assets/iconfont/iconfont.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css'
import '@ionic/vue/css/structure.css'
import '@ionic/vue/css/typography.css'



/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css'
import '@ionic/vue/css/float-elements.css'
import '@ionic/vue/css/text-alignment.css'
import '@ionic/vue/css/text-transformation.css'
import '@ionic/vue/css/flex-utils.css'
import '@ionic/vue/css/display.css'

/* Theme variables */
import './theme/variables.css'

import './registerServiceWorker'

window.addEventListener('DOMContentLoaded', async () => {
  // 数据库初始化
  await databaseInit()
  // const vConsole = new VConsole()

  const app = createApp(App)
    .use(i18n)
    .use(IonicVue, {
      rippleEffect: false,
      swipeBackEnabled: !isMobileSafari(),
      mode: 'ios',
    })
    .use(router)
    .use(hxxToast)

  router.isReady().then(() => {
    app.mount('#app')
  })
})
