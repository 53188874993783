import {CreateAxios, CreateAxiosOptions} from './createAxios'

import { useAccountStore } from '@/stores/account'

const options: CreateAxiosOptions = {
  interceptorsRequestFunc: (config) => {
    const accountStore = useAccountStore()
    // 获取缓存中的token
    const headers: any = {}
    const token = accountStore.accountInfo?.value?.token
    if (token) {
      headers.Authorization = 'Bearer ' + token
    }
    return {
      ...config,
      headers,
    }
  },
  interceptorsResponseFunc: (response) => {
    return response
  },
  responseHandleFunc: (response) => {
    return response.data.data
  },
  responseErrorHandleFunc: (err) => {
    return Promise.reject(err)
  },
  baseURL: process.env.VUE_APP_BASE_API,

}

export const http = new CreateAxios(options)
