import { isPlatform } from '@ionic/vue'

export function isMobileSafari() {
  return isPlatform('ios') && (isPlatform('mobileweb') || isPlatform('pwa'))
}

function debugPlatorm() {
  const PLATFORMS_MAP = {
    ipad: 'ipad',
    iphone: 'iphone',
    ios: 'ios',
    android: 'android',
    phablet: 'phablet',
    tablet: 'tablet',
    cordova: 'cordova',
    capacitor: 'capacitor',
    electron: 'electron',
    pwa: 'pwa',
    mobile: 'mobile',
    mobileweb: 'mobileweb',
    desktop: 'desktop',
    hybrid: 'hybrid',
  }
  console.group('DEBUG platform')
  const table = {} as any
  table.isMobileSafari = isMobileSafari()
  for (const platform in PLATFORMS_MAP) {
    table[platform] = isPlatform(platform as any)
  }
  console.table(table)
  console.groupEnd()
}

debugPlatorm()
