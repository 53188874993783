import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

export interface Result<T = any> {
  data: T
  status: number
  message?: string
}

export interface CreateAxiosOptions extends AxiosRequestConfig {
  interceptorsRequestFunc?: (config: AxiosRequestConfig) => AxiosRequestConfig
  interceptorsResponseFunc?: (res: AxiosResponse) => AxiosResponse
  responseHandleFunc?: (res: AxiosResponse<Result>) => any
  responseErrorHandleFunc?: (err: Error) => void
}

export class CreateAxios {
  private instance: AxiosInstance
  private readonly options: CreateAxiosOptions

  constructor(options: CreateAxiosOptions) {
    this.options = options
    this.instance = this.createAxios(options)
    this.setInstanceInterceptors()
  }

  createAxios(options: CreateAxiosOptions): AxiosInstance {
    return axios.create(options)
  }

  setInstanceInterceptors() {
    this.instance.interceptors.request.use((config: AxiosRequestConfig) => {
      if (this.options.interceptorsRequestFunc) {
        config = this.options.interceptorsRequestFunc(config)
      }
      return config
    })

    this.instance.interceptors.response.use((config: AxiosResponse) => {
      if (this.options.interceptorsResponseFunc) {
        config = this.options.interceptorsResponseFunc(config)
      }
      return config
    })
  }

  request<T = any>(config: AxiosRequestConfig): Promise<T> {
    return new Promise((resolve, reject) => {
      this.instance
        .request<any, AxiosResponse<Result>>({
          ...this.instance.options,
          ...config,
        })
        .then((res: AxiosResponse<Result>) => {
          if (this.options.responseHandleFunc) {
            try {
              const ret = this.options.responseHandleFunc(res)
              resolve(ret)
            } catch (err) {
              reject(err || new Error('request error!'))
            }
            return
          }
          resolve(res as unknown as Promise<T>)
        })
        .catch((err: Error) => {
          if (this.options.responseErrorHandleFunc) {
            reject(this.options.responseErrorHandleFunc(err))
            return
          }
          reject(err)
        })
    })
  }

  get<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({ ...config, method: 'GET' })
  }

  post<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({ ...config, method: 'POST' })
  }

  put<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({ ...config, method: 'PUT' })
  }

  delete<T = any>(config: AxiosRequestConfig): Promise<T> {
    return this.request({ ...config, method: 'DELETE' })
  }
}
