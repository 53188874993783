import { SQLiteDBConnection } from '@capacitor-community/sqlite'
import { useEventBus } from '@vueuse/core'
import { ref } from 'vue'

import { openDB, saveDB } from '../database'

import { DBAdapter, DBLimit, DBOrder } from './index.type'
import { SQLStatement } from './sql-statement'

function sleep(time: number) {
  return new Promise(resolve => setTimeout(resolve, time))
}

export function useDBAdapter<T>(
  tableName: string
): DBAdapter<T> {
  const dataList = ref<Array<any>>([])
  const bus = useEventBus<string>(tableName)

  let db: SQLiteDBConnection
  openDB().then((res) => db = res)

  const waitDB = async (time = 0, stop?: boolean): Promise<void> => {
    if (!db && stop) {
      throw new Error('db 初始化超时')
    }
    if (!db) {
      await sleep(time)
      return waitDB(time + 200, time > 1000)
    }
  }

  const query = async (
    select: Array<string> | '*',
    condition?: Partial<T> | string,
    order?: DBOrder<T>,
    limit?: DBLimit
  ): Promise<T[]> => {
    await waitDB()
    const sqlStatement = new SQLStatement<T>(tableName)
    const statement = sqlStatement
      .query(select)
      .condition(condition)
      .order(order)
      .limit(limit)
      .value()

    const res = await db.query(statement)
    const values = res.values || []
    if (limit?.offset && limit?.offset > 0) {
      dataList.value.push(...values!)
    } else {
      dataList.value = values
    }
    return values
  }

  const insert = async (obj: T): Promise<void> => {
    await waitDB()
    const sqlStatement = new SQLStatement<T>(tableName)
    const statement = sqlStatement.insert(obj).value()

    await db.execute(statement)
    bus.emit('insert')
  }

  const insertSet = async (objs: Array<T>): Promise<void> => {
    await waitDB()
    const statements = []
    for (const obj of objs) {
      const sqlStatement = new SQLStatement<T>(tableName)
      statements.push({
        statement: sqlStatement.insert(obj).value(),
      })
    }

    await db.executeSet(statements)
    bus.emit('insert')
  }

  const update = async (
    condition: Partial<T> | string,
    changes: Partial<T>
  ): Promise<void> => {
    await waitDB()
    const sqlStatement = new SQLStatement<T>(tableName)
    const statement = sqlStatement.update(changes).condition(condition).value()
    await db.execute(statement)
    bus.emit('update')
  }

  const deleteOne = async (condition: Partial<T> | string): Promise<void> => {
    await waitDB()
    const sqlStatement = new SQLStatement<T>(tableName)
    const statement = sqlStatement.delete().condition(condition).value()
    await db.execute(statement)
    bus.emit('delete')
  }

  const watch = (listener: (event: string) => void) => {
    bus.on(listener)
  }

  const store = () => {
    return saveDB()
  }

  return {
    dataList,
    query,
    insert,
    insertSet,
    update,
    deleteOne,
    watch,
    store,
  }
}
