/**
 * 账号
 */
export interface Account {
  uin: string
  email: string
  name?: string
  token: string
  state: number
}

const TABLE_ACCOUNT = `
  CREATE TABLE account (
    uin TEXT PRIMARY KEY,
    email TEXT NOT NULL,
    name TEXT,
    token TEXT NOT NULL,
    state TEXT NOT NULL
  );
`

/**
 * 祷告
 */
export interface Prayer {
  prayerId: string
  description: string
  reminderType: number
  reminderNextTime: string
  title: string
  createdStamp: number
}

export const SQL_STATEMENT_FOR_SETUP = `
  ${TABLE_ACCOUNT}
`
