export const zh = {
  button: {
    signUp: '同意并注册',
    back: '返回',
    refresh: '刷新',
  },
  message: {
    hello: '你好',
  },
}
