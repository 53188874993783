import {ProfileParams} from '@/apis/model/profile-model'
import {http} from '@/utils/http'

/**
 * 修改Profile
 * @param avatar
 * @param name
 * @returns {Promise}
 */
export const profileSave = (data: ProfileParams ) => {
  return http.post({
    url: '/profile/save',
    data,
  })
}

/**
 * 获取Profile
 * @param uin
 * @returns {Promise}
 */
export const profileGet = (params: {uin: string} ) => {
  return http.get({
    url: '/profile/get',
    params,
  })
}