import type {
  CreatePrayerGroupReq,
  CreatePrayerGroupRes, PrayerGroupDetailParams,
  PrayerGroupInvitationEmailReq,
  PrayerGroupInvitationTokenReq,
  PrayerGroupInvitationTokenRes,
  PrayerGroupListParams,
  PrayerGroupListResult
} from '@/apis/model/prayer-group-model'
import {http} from '@/utils/http'


export const createPrayerGroupApi = (data: CreatePrayerGroupReq) => http.post<CreatePrayerGroupRes>({
  url: '/group/create',
  data,
})

export const getPrayerGroupListApi = (params?: PrayerGroupListParams) => http.get<PrayerGroupListResult>({
  url: '/group/list',
  params,
})

export const getPrayerGroupDetailApi = (params?: PrayerGroupDetailParams) => http.get({
  url: '/group/detail',
  params,
})

export const getPrayerGroupInvitationCodeApi = (data: PrayerGroupInvitationTokenReq) => http.post<PrayerGroupInvitationTokenRes>({
  url: '/group/invitation/url',
  data,
})

export const sendPrayerGroupInvitationEmailApi = (data: PrayerGroupInvitationEmailReq) => http.post({
  url: '/group/invitation/email/send',
  data,
})



